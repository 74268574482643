<template>
  <svg
    id="linkdinContainer"
    data-name="linkdin-c"
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
  >
    <rect
      id="linkdinRect"
      data-name="Rectangle 2634"
      width="26"
      height="26"
      rx="7"
      fill="#569956"
    />
    <path
      id="iconAwesomeLinkedinIn"
      data-name="Icon awesome-linkedin-in"
      d="M2.69,12.02H.2V4H2.69ZM1.443,2.9A1.45,1.45,0,1,1,2.886,1.444,1.455,1.455,0,0,1,1.443,2.9ZM12.017,12.02H9.53V8.113c0-.931-.019-2.125-1.3-2.125C6.939,5.988,6.74,7,6.74,8.046V12.02H4.251V4h2.39V5.09h.035a2.618,2.618,0,0,1,2.358-1.3c2.522,0,2.986,1.661,2.986,3.818V12.02Z"
      transform="translate(7 7)"
      fill="#fff"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '17.5',
    },
    width: {
      type: String,
      default: '17.5',
    },
    color: {
      type: String,
      default: 'menu',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#linkdinContainer {
  #linkdinRect {
    fill: var(--footer-icon);
  }
}
#linkdinContainer:hover {
  #linkdinRect {
    fill: var(--white);
  }
  #iconAwesomeLinkedinIn {
    fill: var(--primary-green);
  }
}
</style>
